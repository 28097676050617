html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tokenText {
  text-decoration: dotted;
  text-decoration-line: underline;
  cursor: pointer;
  user-select: none;
}
.tokenText:hover {
  opacity: 0.7;
}
.customPagination .MuiTablePagination-selectLabel,
.customPagination .MuiTablePagination-displayedRows {
  margin-bottom: 0;
}
.tabButton,
.tabButton:focus {
  outline: none !important;
}
button,
button:focus {
  outline: none;
}
.convertCharItem {
  margin-left: 17px;
  width: 48%;
  min-height: 56px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.convertCharItem.newCharsetButton {
  min-height: 40px;
  height: 40px;
}
.convertCharLeft {
  width: 49%;
  flex: 0 0 49%;
  height: 100%;
}

.convertCharCenter {
  width: 9%;
  flex: 0 0 9%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: grid;
  height: 56px;
}

.convertCharRight {
  width: 39%;
  flex: 0 0 39%;
  height: 100%;
  height: 56px;
}
.fromChiplist {
  width: 100%;
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
}
.customRow {
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.cameraResolutionInfoArea {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -20px;
}
.breadcrumb-item:hover {
  text-decoration: underline;
  color: #1876d2;
  transition: all 0.2s;
}
.breadCrumbContainer {
  margin-left: -1%;
}

.profileSection .ps__rail-x,
.profileSection .ps__rail-y {
  display: none !important;
}

.authAnim::before {
  content: "";
  position: absolute;
  top: 23%;
  left: 37%;
  width: 243px;
  height: 210px;
  background-size: 380px;
  background-image: url(https://berrydashboard.io/static/media/auth-blue-card.209358074610c260ef74c448d420f8fd.svg);
  background-repeat: no-repeat;
  background-position: center center;
  animation: 15s ease-in-out 1s infinite normal none running wings;
}

.authAnim::after {
  content: "";
  position: absolute;
  top: 32%;
  left: 40%;
  width: 313px;
  background-size: 380px;
  height: 280px;
  background-image: url(https://berrydashboard.io/static/media/auth-purple-card.8b41ef131d2a063e1cd5feb7fae4413a.svg);
  background-repeat: no-repeat;
  background-position: center center;
  animation: 15s ease-in-out 0s infinite normal none running wings;
}

.uploadImage-image {
  width: 150px;
  height: 150px;
  display: block;
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 10px;
}

@-webkit-keyframes wings {
  50% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes wings {
  50% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.react-datetime-picker {
  width: 100%;
  min-height: 56px;
}
.react-datetime-picker__wrapper {
  border-color:  #c4c4c4;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datetime-picker.warn .react-datetime-picker__wrapper {
  border-color: #f44336;
}

.warn > .tox-tinymce {
  border-color: #f44336;
}
